import React, { Component } from "react";
import { isNotValue } from "../../utils/general";
import "./Style.css";

type Action = {
  label: string;
  handleClick: () => void;
  triggerInterval?: number; // TODO: implement
  disabled?: boolean;
};

type Props = {
  label: String;
  labelAction?: () => void;
  isTopArea?: boolean;
  bottomAction?: Action;
  children: React.ReactNode;
};

type State = {};

class ContentArea extends Component<Props, State> {
  render() {
    const contentClasses = this.props.isTopArea ? "content top" : "content";

    return (
      <div className="content-area">
        <div className={contentClasses}>
          {this.renderLabel()}
          {this.props.children}
          <div className="bottom">{this.renderButton()}</div>
        </div>
      </div>
    );
  }

  renderLabel = () => {
    const { label, labelAction } = this.props;
    if (isNotValue(labelAction)) {
      return <div className="label">{label}</div>;
    }

    return <button onClick={labelAction}>{label}</button>;
  };

  renderButton = () => {
    if (!this.props.bottomAction) {
      return;
    }

    const { label, handleClick, disabled } = this.props.bottomAction;
    console.log("Disabled: " + disabled);
    return (
      <button
        className={disabled ? "disabled" : ""}
        onClick={handleClick}
        disabled={disabled}
      >
        {label}
      </button>
    );
  };
}

export default ContentArea;
