export default class Formatter {
  public static getTime(timeInMs: number) {
    const absTimeInMs = Math.abs(timeInMs);

    const absTimeInDays = Math.ceil(absTimeInMs / (1000 * 60 * 60 * 24));
    if (absTimeInDays < 100) {
      return absTimeInDays + " days";
    }

    const absTimeInMonths = Math.ceil(absTimeInDays / 30.5);
    if (absTimeInMonths < 12) {
      return absTimeInMonths + " months";
    }

    const absTimeInYears = Math.ceil(absTimeInDays / 365);
    const yearSuffix = absTimeInYears === 1 ? "year" : "years";
    return absTimeInYears + " " + yearSuffix;
  }

  public static getDays(timeInMs: number) {
    const absTimeInMs = Math.abs(timeInMs);
    const absTimeInDays = Math.ceil(absTimeInMs / (1000 * 60 * 60 * 24));
    const daySuffix = absTimeInDays === 1 ? "day" : "days";
    return absTimeInDays + " " + daySuffix;
  }

  public static getBtcPrice(price: number) {
    const formattedBtcPrice = this.getPrice(price, "BTC");
    if (formattedBtcPrice) {
      return formattedBtcPrice;
    }

    return this.getSatPrice(price);
  }

  public static getSatPrice(btcPrice: number) {
    const priceInSats = btcPrice * 100000000;
    return this.getPrice(priceInSats, "SAT");
  }

  private static getPrice(price: number, suffix: string) {
    const milionTenths = Math.round(price / 100000);
    if (milionTenths > 0) {
      return (milionTenths / 10).toFixed(1) + "M " + suffix;
    }

    const thosandTenths = Math.round(price / 100);
    if (thosandTenths > 0) {
      return (thosandTenths / 10).toFixed(1) + "K " + suffix;
    }

    const tenths = Math.round(price / 0.1);
    if (tenths > 0) {
      return (tenths / 10).toFixed(1) + " " + suffix;
    }

    return null;
  }
}
