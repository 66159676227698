import React, { Component } from "react";
import { StatusData } from "../../shared/types";
import Formatter from "../../utils/Formatter";
import ContentArea from "../ContentArea/ContentArea";
import "./Style.css";

type Props = {
  status: StatusData;
};

type State = {
  isInitialized: boolean;
  hashrate: number;
  difficulty: number;
  blockRewardIncludingFees: number;
};

class CurrentBlockStatsArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialized: false,
      difficulty: 0,
      hashrate: 0,
      blockRewardIncludingFees: 0,
    };
  }

  private renderPlaceholder() {
    return (
      <div className="content-lines">
        Stats content is in progress. Stay tuned...
      </div>
    );
  }

  private renderStats(status: NonNullable<StatusData>) {
    const { blockRewardIncludingFees } = status.mining;
    const {
      feesInCurrentBlock,
      transactionsCountInCurrentBlock,
      averageFee,
      currentBlock,
    } = status.network;

    return (
      <div className="content-lines">
        <div className="content-line">
          Number: <b>{currentBlock}</b> |&nbsp;
        </div>
        <div className="content-line">
          Reward: <b>{blockRewardIncludingFees.toFixed(3)} BTC</b> |&nbsp;
        </div>
        <div className="content-line">
          Fees: <b>{Formatter.getBtcPrice(feesInCurrentBlock / 100000000)}</b>{" "}
          |&nbsp;
        </div>
        <div className="content-line">
          Transactions: <b>{transactionsCountInCurrentBlock}</b> |&nbsp;
        </div>
        <div className="content-line">
          Average Fee: <b>{Formatter.getBtcPrice(averageFee / 100000000)}</b>{" "}
        </div>
      </div>
    );
  }

  render() {
    const areaLable = "Current Block";

    return (
      <ContentArea label={areaLable}>
        {this.props.status
          ? this.renderStats(this.props.status)
          : this.renderPlaceholder()}
      </ContentArea>
    );
  }
}

export default CurrentBlockStatsArea;
