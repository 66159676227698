import React, { Component, Fragment } from "react"; // let's also import Component
import { useParams, useNavigate } from "react-router-dom";
import { DetailArea } from "../DetailArea/DetailArea";
import Layout from "./Layout/Layout";
import "./Style.css";

export const DetailPage = () => {
  let { id } = useParams();

  return (
    <Layout>
      <DetailArea id={id}></DetailArea>
    </Layout>
  );
};
