export default class AlternativeAdapter {
  public static async getFearAndGreedStats() {
    return await this.getSafely(async () => {
      const response = await fetch(
        "https://api.alternative.me/fng/?limit=0&fbclid=IwAR09FZoRu_Hd6jalB3zPSuUNxNgSpsbld7GAsLzO_JDvwZUGlKfTBvrXGH4",
      );
      const json = await response.json();
      return json.data;
    });
  }

  private static async getSafely(callback: () => {}) {
    try {
      const data = await callback();
      return data;
    } catch (e) {
      console.error("Alternative.me error", e);
      return {};
    }
  }
}
