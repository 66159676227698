import React, { Component, memo } from "react";
import { useNavigate } from "react-router-dom";
import Formatter from "../../utils/Formatter";
import "./Style.css";

type Props = {
  id: String;
  name: String;
  symbol: String;
  image: string;
  btcPrice?: number;
  btcPriceDrop?: number;
  btcMarketCap?: number;
  btcAthDate?: Date;
  badge?: string;
};

export const CurrencyCard = (props: Props) => {
  const {
    id,
    name,
    badge,
    symbol,
    image,
    btcPrice,
    btcPriceDrop,
    btcMarketCap,
    btcAthDate,
  } = props;
  const now = new Date();

  const navigate = useNavigate();
  const navigateToDetail = () => navigate(`detail/${id}`);

  return (
    <div className="currency-drop-card" onClick={navigateToDetail}>
      <img src={image} className="image" />
      <div className="title">
        <div className="badge">{badge}</div>
        <div className="name">
          <b>{name}</b> {` [${symbol.toUpperCase()}]`}
        </div>
        <div className="price">
          Price: <b>{btcPrice ? Formatter.getSatPrice(btcPrice) : ""}</b>
        </div>
      </div>
      <div className="delimiter"></div>
      <div className="price-drop">
        Down <b>{btcPriceDrop ? Math.abs(btcPriceDrop).toFixed(1) : "-"} %</b>{" "}
        from its BTC ATH.
      </div>
      <div className="market-cap">
        Market Capitalization is{" "}
        <b>{btcMarketCap ? Formatter.getBtcPrice(btcMarketCap) : ""}</b>.
      </div>
      <div className="from-ath">
        Bitcoin ATH{" "}
        <b>
          {btcAthDate
            ? Formatter.getTime(now.getTime() - btcAthDate.getTime())
            : ""}
        </b>{" "}
        ago.
      </div>
    </div>
  );
};
