import React, { Component } from "react";
import "./Style.css";

import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ON_CHAIN_BTC_ADDR = "bc1q9j0r76vfnjyejarrx3634q9u937ymwlppc9pgt";
const LNURL_BTC_ADDR =
  "LNURL1DP68GURN8GHJ7AMPD3KX2AR0VEEKZAR0WD5XJTNRDAKJ7TNHV4KXCTTTDEHHWM30D3H82UNVWQHHXAR0D93KSETVD4JHGVENUHSWDK";
const CONTACT_MAIL = "contact@inbtcterms.com";

const showToast = (text: string) => {
  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    // progress: true,
  };

  toast(text, toastConfig);
};

export default () => {
  return (
    <div className="footer">
      <div className="content">
        <div className="donate">
          Donate addresses:
          <div>
            <div style={{ float: "left" }}>
              <div style={{ float: "left" }}> On-chain&nbsp; </div>
              <div
                className="address"
                onClick={() => {
                  if (!window.isSecureContext) {
                    return console.log("Not secure, unable to copy");
                  }
                  navigator.clipboard.writeText(ON_CHAIN_BTC_ADDR);
                  showToast("Address copied to clipboard!"); // TODO: use something withou vulnerability
                }}
              >
                {ON_CHAIN_BTC_ADDR.slice(0, 8)}... &#10064;&nbsp;&nbsp;
              </div>
            </div>

            <div style={{ float: "left" }}>
              <div style={{ float: "left" }}> Lightning&nbsp; </div>
              <div
                className="address"
                onClick={() => {
                  if (!window.isSecureContext) {
                    return console.log("Not secure, unable to copy");
                  }
                  navigator.clipboard.writeText(LNURL_BTC_ADDR);
                  showToast("Address copied to clipboard!"); // TODO: use something withou vulnerability
                }}
              >
                {LNURL_BTC_ADDR.slice(0, 8)}... &#10064;&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="contact">
          <div className="label">E-mail:</div>
          <div
            className="e-mail"
            onClick={() => {
              if (!window.isSecureContext) {
                return console.log("Not secure, unable to copy");
              }
              navigator.clipboard.writeText(CONTACT_MAIL);
              showToast("Contact copied to clipboard!"); // TODO: use something withou vulnerability
            }}
          >
            {CONTACT_MAIL} &#10064;
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
