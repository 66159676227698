import React, { Component } from "react";
import { StatusData } from "../../shared/types";
import Formatter from "../../utils/Formatter";
import ContentArea from "../ContentArea/ContentArea";
import "./Style.css";

const TERA = 10 ** 12;
const EXA = 10 ** 18;

type Props = {
  status: StatusData;
};

type State = {
  isInitialized: boolean;
  hashrate: number;
  difficulty: number;
  blockRewardIncludingFees: number;
};

class MiningStatsArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialized: false,
      difficulty: 0,
      hashrate: 0,
      blockRewardIncludingFees: 0,
    };
  }

  private renderPlaceholder() {
    return (
      <div className="content-lines">
        Stats content is in progress. Stay tuned...
      </div>
    );
  }

  private renderStats(status: NonNullable<StatusData>) {
    const { difficulty, hashrate } = status.mining;

    return (
      <div className="content-lines">
        <div className="content-line">
          Hashrate: <b>{(hashrate / EXA).toFixed(2)} EH/s</b> |&nbsp;
        </div>
        <div className="content-line">
          Difficulty: <b>{(difficulty / TERA).toFixed(2)} T</b>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentArea label="Mining">
        {this.props.status
          ? this.renderStats(this.props.status)
          : this.renderPlaceholder()}
      </ContentArea>
    );
  }
}

export default MiningStatsArea;
