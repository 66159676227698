export default class MempoolSpaceAdapter {
  public static async getLastBlockStats() {
    return await this.getSafely(async () => {
      const response = await fetch("https://mempool.space/api/v1/blocks");
      const json = await response.json();
      return json[0];
    });
  }

  private static async getSafely(callback: () => {}) {
    // TODO: move into separate class
    try {
      const data = await callback();
      return data;
    } catch (e) {
      console.error("Bitnodes error", e);
      return [];
    }
  }
}
