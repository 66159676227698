import React, { Component } from "react";
import "./Style.css";

type Props = {
  title: String;
};

type State = {};

class Header extends Component<Props, State> {
  render() {
    const { title } = this.props;
    return (
      <div className="header">
        <div className="content">
          <div className="title">{title}</div>
          <img src={"/images/bitcoin.png"} className="logo" />
        </div>
      </div>
    );
  }
}

export default Header;
