import React, { Component } from "react";
import { StatusData } from "../../shared/types";
import BlockCalculator from "../../utils/Calculators/InformationCalculator";
import ContentArea from "../ContentArea/ContentArea";
import "./Style.css";

type Props = {
  status: StatusData;
};
type State = {
  isInitialized: boolean;
  currentBlock: number;
  totalNodes: number;
};

class StatsArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialized: false,
      currentBlock: 0,
      totalNodes: 0,
    };
  }

  private renderPlaceholder() {
    return (
      <div className="content-lines">
        Stats content is in progress. Stay tuned...
      </div>
    );
  }

  private renderStats(networkStatus: NonNullable<StatusData>["network"]) {
    const { currentBlock } = networkStatus;

    return (
      <div className="content-lines">
        {/* <div className='content-line'>Active Nodes: <b>{totalNodes}</b> |&nbsp;</div>   TODO: find new source for nodes*/}
        {/* <div className='content-line'>Current Block: <b>{currentBlock}</b> |&nbsp;</div> */}
        <div className="content-line">
          Age: <b>{BlockCalculator.calculateNetworkAge()}</b> |&nbsp;{" "}
        </div>
        <div className="content-line">
          Available Supply:{" "}
          <b>{BlockCalculator.calculateAvailableSupply(currentBlock)}</b>{" "}
          |&nbsp;{" "}
        </div>
        <div className="content-line">
          Blocks Until Halving:{" "}
          <b>{BlockCalculator.calculateBlocksLeftToHalving(currentBlock)}</b>{" "}
          |&nbsp;
        </div>
        <div className="content-line">
          Cycle Progress:{" "}
          <b>{BlockCalculator.calculateCycleProgress(currentBlock)}</b> |&nbsp;
        </div>
        <div className="content-line">
          Halving in:{" "}
          <b>{BlockCalculator.estimateTimeToNextHalving(currentBlock)}</b>{" "}
          |&nbsp;
        </div>
        <div className="content-line">
          Halving Date Estimate:{" "}
          <b>{BlockCalculator.estimateNextHalvingDate(currentBlock)}</b>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentArea label="General" isTopArea={true}>
        {this.props.status
          ? this.renderStats(this.props.status.network)
          : this.renderPlaceholder()}
      </ContentArea>
    );
  }
}

export default StatsArea;
