import React, { Component } from "react";
import "./Style.css";

export default function Dialog() {
  const closeDialog = () => {
    const modalDialog = document.querySelector("#modalDialog") as any; // TODO: remove any
    modalDialog?.close();
  };

  return (
    <dialog id="modalDialog" className="">
      <h3>Limit reached</h3>
      <p>
        This page uses CoinGecko free API with its limitations. Please try again
        in a minute.
      </p>
      <button id="closeModal" onClick={closeDialog}>
        Close
      </button>
    </dialog>
  );
}
