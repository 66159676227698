export default class MinerstatAdapter {
  public static async getBitcoinMiningStats() {
    return await this.getSafely(async () => {
      const response = await fetch(
        "https://api.minerstat.com/v2/coins?list=BTC",
      );
      const json = await response.json();
      return json[0];
    });
  }

  private static async getSafely(callback: () => {}) {
    try {
      const data = await callback();
      return data;
    } catch (e) {
      console.error("Minerstat error", e);
      return {};
    }
  }
}
