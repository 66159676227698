import React from 'react';
import Layout from './Layout/Layout';
import "./Style.css";

export function NotFoundPage() {
  return (
    <Layout>
        <div className='center'>
            <h2>404 - Page Not Found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
  </Layout>
  );
}
