import Formatter from "../Formatter";

const BLOCKS_IN_CYCLE = 210000;
const MAX_SUPPLY = 20999999.9769;
const GENESIS_BLOCK_DATE = "01/3/2009";

export default class BlockCalculator {
  public static calculateNextHalvingBlock(currentBlock: number): number {
    return Math.ceil(currentBlock / BLOCKS_IN_CYCLE) * BLOCKS_IN_CYCLE;
  }

  public static calculateBlocksLeftToHalving(currentBlock: number): number {
    return this.calculateNextHalvingBlock(currentBlock) - currentBlock;
  }

  public static calculateCycleProgress(currentBlock: number): string {
    return (
      (
        100 -
        (this.calculateBlocksLeftToHalving(currentBlock) / BLOCKS_IN_CYCLE) *
          100
      ).toFixed(1) + "%"
    );
  }

  public static estimateTimeToNextHalving(currentBlock: number): string {
    const numberOfBlocksLeft = this.calculateNumberOfBlocksLeft(currentBlock);
    const timeLeftInMs = numberOfBlocksLeft * 10 * 60 * 1000;
    return Formatter.getDays(timeLeftInMs);
  }

  public static estimateNextHalvingDate(currentBlock: number): string {
    const blocksLeft = this.calculateNumberOfBlocksLeft(currentBlock);
    const daysLeft = (blocksLeft * 10) / 60 / 24;

    let currentDate = new Date();
    let estimatedDate = new Date(
      currentDate.setDate(currentDate.getDate() + daysLeft),
    );

    return estimatedDate.toLocaleString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  public static calculateCurrentEmission(currentBlock: number): number {
    const currentCycle = Math.ceil(currentBlock / BLOCKS_IN_CYCLE);

    let availableSupply = 0;
    for (let i = 1; i <= currentCycle; i++) {
      const isCompletedCycle = i * BLOCKS_IN_CYCLE < currentBlock;
      const currentBlockReward = 50 / Math.pow(2, i - 1);
      if (isCompletedCycle) {
        availableSupply += BLOCKS_IN_CYCLE * currentBlockReward;
      } else {
        const blocksInCurrentCycle = currentBlock - (i - 1) * BLOCKS_IN_CYCLE;
        availableSupply += blocksInCurrentCycle * currentBlockReward;
      }
    }

    return availableSupply;
  }

  public static calculateAvailableSupply(currentBlock: number): string {
    const currentEmission = this.calculateCurrentEmission(currentBlock);
    return (
      Formatter.getBtcPrice(currentEmission) +
      " ( " +
      ((currentEmission / MAX_SUPPLY) * 100).toFixed(2) +
      "% )"
    );
  }

  public static calculateNetworkAge(): string {
    const genesisBlockDate = new Date(GENESIS_BLOCK_DATE);
    const currentDate = new Date();

    const differenceInTime = currentDate.getTime() - genesisBlockDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInTruncatedDays = Math.trunc(differenceInDays);
    const differenceInYears = Math.floor(differenceInTruncatedDays / 365.25);

    return (
      differenceInTruncatedDays + " days (~" + differenceInYears + " years)"
    );
  }

  private static calculateNumberOfBlocksLeft(currentBlock: number): number {
    return this.calculateNextHalvingBlock(currentBlock) - currentBlock;
  }
}
