import React, { Component } from "react";
import { StatusData } from "../../shared/types";
import ContentArea from "../ContentArea/ContentArea";
import "./Style.css";

type Props = {
  status: StatusData;
};

type State = {
  isInitialized: boolean;
  value: number;
  classification: string; // value_classification
  hundredDayAverage: number;
  yearAverage: number;
};

class FearAndGreedStatsArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialized: false,
      value: 0,
      classification: "",
      hundredDayAverage: 0,
      yearAverage: 0,
    };
  }

  private renderPlaceholder() {
    return (
      <div className="content-lines">
        Stats content is in progress. Stay tuned...
      </div>
    );
  }

  private renderStats(
    fearAndGreedStatus: NonNullable<StatusData>["fearAndGreed"],
  ) {
    const { value, classification, hundredDayAverage, yearAverage } =
      fearAndGreedStatus;

    return (
      <div className="content-lines">
        <div className="content-line">
          Classification: <b>{classification}</b> |&nbsp;
        </div>
        <div className="content-line">
          Current Score: <b>{value.toFixed(0)}</b> |&nbsp;
        </div>
        <div className="content-line">
          100 Days Average Score: <b>{hundredDayAverage.toFixed(1)}</b> |&nbsp;
        </div>
        <div className="content-line">
          Year Average Score: <b>{yearAverage.toFixed(1)}</b>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ContentArea label="Fear And Greed">
        {this.props.status
          ? this.renderStats(this.props.status.fearAndGreed)
          : this.renderPlaceholder()}
      </ContentArea>
    );
  }
}

export default FearAndGreedStatsArea;
