export const isValue = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

export const isNotValue = (value: unknown): value is null | undefined =>
  value === null || value === undefined;

export const isEmptyObject = (value: unknown): boolean =>
  isObject(value) &&
  Object.keys(value).length === 0 &&
  value.constructor === Object;

export const isNonEmptyObject = <T = Record<string, unknown>>(
  value: unknown,
): value is NonNullable<T> =>
  isObject(value) &&
  Object.keys(value).length > 0 &&
  value.constructor === Object;

export const isObject = <T = Record<string, unknown>>(
  value: unknown,
): value is T => isValue(value) && typeof value === "object";

export const isString = (value: unknown): value is string =>
  isValue(value) && typeof value === "string";

export const isEmptyString = (value: unknown): value is string =>
  isString(value) && value.length === 0;

export const isNonEmptyString = (value: unknown): value is string =>
  isString(value) && value.length > 0;

export const isNumber = (value: unknown): value is number =>
  isValue(value) && typeof value === "number";

export const isArray = <T = any[]>(value: unknown): value is NonNullable<T> =>
  isValue(value) && Array.isArray(value);

export const isEmptyArray = (value: unknown): boolean =>
  isArray(value) && value.length === 0;

export const isNonEmptyArray = (value: unknown): boolean =>
  isArray(value) && value.length > 0;

export const isFunction = (value: unknown): value is Function =>
  typeof value === "function";
