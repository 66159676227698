import React, { Component, Fragment } from "react"; // let's also import Component
import { Children } from "react";
import CurrencyCardArea from "../../CurrencyCardArea/CurrencyCardArea";
import CurrentBlockStatsArea from "../../CurrentBlockStatsArea/CurrentBlockStatsArea";
import Dialog from "../../Dialog/Dialog";
import FearAndGreedStatsArea from "../../FearAndGreedStats/FearAndGreedStatsArea";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import MiningStatsArea from "../../MiningStatsArea/MiningStatsArea";
import StatsArea from "../../StatsArea/StatsArea";
import "./Style.css";

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  return (
    <Fragment>
      <br />
      <Header title="In Bitcoin Terms" />
      {props.children}
      <br />
      <Footer />
      <Dialog />
    </Fragment>
  );
};

export default Layout;
