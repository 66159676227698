import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import SearchResults from './components/SearchResults';
import "./App.css";
import { HomePage } from "./components/Pages/HomePage";
import { DetailPage } from "./components/Pages/DetailPage";
import { NotFoundPage } from "./components/Pages/NotFoundPage";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/stats" Component={HomePage} /> // TODO: add stats page
          <Route path="/detail/:id" Component={DetailPage} />
          <Route path="*" Component={NotFoundPage} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
