import { StatusData } from "../shared/types";
import AlternativeAdapter from "../utils/Adapters/AlternativeAdapter";
// import BlockchainInfoAdapter from "../utils/Adapters/BlockchainInfoAdapter";
import MempoolSpaceAdapter from "../utils/Adapters/MempoolSpaceAdapter";
import MinerstatAdapter from "../utils/Adapters/MinerstatAdapter";

export default class StatusDataLoader {
  private static statusData: StatusData;

  public static getStatusData = async (): Promise<StatusData> => {
    if (this.statusData === undefined) {
      this.statusData = await this.fetchStatusData();
    }

    return this.statusData;
  };

  private static fetchStatusData = async (): Promise<StatusData> => {
    const mining = await this.getMiningStatus();
    const network = await this.getNetworkStatus();
    const fearAndGreed = await this.getFearAndGreedStatus();

    return {
      mining,
      network,
      fearAndGreed,
    };
  };

  private static getMiningStatus = async (): Promise<
    NonNullable<StatusData>["mining"]
  > => {
    const miningStatus =
      (await MinerstatAdapter.getBitcoinMiningStats()) as any;

    return {
      difficulty: miningStatus.difficulty,
      hashrate: miningStatus.network_hashrate,
      blockRewardIncludingFees: miningStatus.reward_block,
    };
  };

  private static getNetworkStatus = async (): Promise<
    NonNullable<StatusData>["network"]
  > => {
    // const networkStatus = (await BlockchainInfoAdapter.getStats() as any); // TODO: check if needed
    const networkStatus =
      (await MempoolSpaceAdapter.getLastBlockStats()) as any;

    return {
      // totalNodes: networkStatus.total_nodes,
      transactionsCountInCurrentBlock: networkStatus.tx_count,
      feesInCurrentBlock: networkStatus.extras.totalFees,
      averageFee: networkStatus.extras.avgFee,
      rewardInCurrentBlock: networkStatus.extras.reward,
      currentBlock: networkStatus.height,
    };
  };

  private static getFearAndGreedStatus = async (): Promise<
    NonNullable<StatusData>["fearAndGreed"]
  > => {
    const fearAndGreedData =
      (await AlternativeAdapter.getFearAndGreedStats()) as any;
    const fearAndGreedStatus = fearAndGreedData[0];

    return {
      value: parseInt(fearAndGreedStatus.value),
      classification: fearAndGreedStatus.value_classification,
      hundredDayAverage: this.getDaysAverage(fearAndGreedData, 100),
      yearAverage: this.getDaysAverage(fearAndGreedData, 365),
    };
  };

  private static getDaysAverage = (
    data: { value: string }[],
    days: number,
  ): number => {
    const daysStats = data.slice(1, days + 1);
    let totalScore = 0;
    daysStats.forEach((element: { value: string }) => {
      totalScore += parseInt(element.value);
    });
    return totalScore / days;
  };
}
