import { isNotValue, isValue } from "../general";

const RELOAD_INTERVAL_IN_MINUTES = 15; // TODO: check more appropriate
const TIMESTAMP = "timestamp";
const SECONDS_IN_YEAR = 31556926;

export default class CoinGekkoAdapter {
  public static async getCurrencyStats(currencyId: string, force?: boolean) {
    const currencyStats = JSON.parse(localStorage.getItem(currencyId) ?? "{}");
    const timestamp = currencyStats[TIMESTAMP];

    if (
      isNotValue(force) &&
      isValue(timestamp) &&
      !this.shouldReload(timestamp)
    ) {
      return currencyStats.data;
    }

    return await this.getSafely(async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${currencyId}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=true&sparkline=false`,
      );
      const data = await response.json();

      localStorage.setItem(
        currencyId,
        JSON.stringify({
          [TIMESTAMP]: Date.now(),
          data,
        }),
      );

      return data;
    }, {});
  }

  public static async getCurrencyPlotData(currencyId: string, force?: boolean) {
    const key = currencyId + "_plot";
    const currencyStats = JSON.parse(localStorage.getItem(key) ?? "{}");
    const timestamp = currencyStats[TIMESTAMP];

    if (
      isNotValue(force) &&
      isValue(timestamp) &&
      !this.shouldReload(timestamp)
    ) {
      return currencyStats.data;
    }

    const to = Math.floor(Date.now() / 1000);
    const from = to - SECONDS_IN_YEAR;

    return await this.getSafely(async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${currencyId}/market_chart/range?vs_currency=btc&from=${from}&to=${to}`,
      );
      const data = await response.json();

      localStorage.setItem(
        key,
        JSON.stringify({
          [TIMESTAMP]: Date.now(),
          data,
        }),
      );

      return data;
    }, {});
  }

  public static async getTopCurrencesByMarketCap(
    count: number,
    force?: boolean,
  ) {
    // TODO: called twice
    const TOP_CURRENCES_INFO = "topCurrencesInfo";

    const topCurrencesInfo = JSON.parse(
      localStorage.getItem(TOP_CURRENCES_INFO) ?? "{}",
    );
    const timestamp = topCurrencesInfo[TIMESTAMP];

    if (
      isNotValue(force) &&
      count === topCurrencesInfo?.data?.length &&
      isValue(timestamp) &&
      !this.shouldReload(timestamp)
    ) {
      return topCurrencesInfo.data;
    }

    return await this.getSafely(async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=btc&order=market_cap_desc&per_page=${count}&page=1&sparkline=false`,
      );
      // TODO: check fail
      const data = await response.json();
      localStorage.setItem(
        TOP_CURRENCES_INFO,
        JSON.stringify({
          [TIMESTAMP]: Date.now(),
          data,
        }),
      );

      return data;
    }, []);
  }

  private static shouldReload(timestamp: number) {
    // TODO: can be util
    console.log(
      "should reload: " +
        ((Date.now() - timestamp) / 1000 / 60 > RELOAD_INTERVAL_IN_MINUTES),
    );
    return (Date.now() - timestamp) / 1000 / 60 > RELOAD_INTERVAL_IN_MINUTES;
  }

  private static async getSafely(
    callback: () => {},
    errorDefaultValue: {} | [],
  ) {
    try {
      const data = await callback();
      return data;
    } catch (e) {
      console.error("CoinGekkoAdapter error", e);
      return errorDefaultValue;
    }
  }
}
