import React, { Component, Fragment, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { isEmptyObject, isNotValue } from "../../utils/general";
import ContentArea from "../ContentArea/ContentArea";
import "./Style.css";
import createPlotlyComponent from "react-plotlyjs";
//See the list of possible plotly bundles at https://github.com/plotly/plotly.js/blob/master/dist/README.md#partial-bundles or roll your own
import Plotly from "plotly.js/dist/plotly-cartesian";
import CoinGekkoAdapter from "../../utils/Adapters/CoinGekkoAdapter";
import Formatter from "../../utils/Formatter";
const PlotlyComponent = createPlotlyComponent(Plotly);

type Props = {
  id: string;
};

export const DetailArea = (props: Props) => {
  const [currencyStats, setCurrencyStats] = useState();
  const [currencyPlotData, setCurrencyPltoData] = useState();
  const { id: currencyDetailId } = props;

  if (isNotValue(currencyStats) || isNotValue(currencyPlotData)) {
    loadCurrencyInfo(currencyDetailId, setCurrencyStats, setCurrencyPltoData);
  }

  if (isNotValue(currencyStats) || isNotValue(currencyPlotData)) {
    return renderLoading();
  }

  if (isEmptyObject(currencyStats) || isEmptyObject(currencyPlotData)) {
    return renderLoadingFailed();
  }

  return renderDetail(currencyStats, currencyPlotData);
};

const Wrapper = (props: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const labelAction = () => navigate("/");
  return (
    <ContentArea label={`< Back to List`} labelAction={labelAction} isTopArea>
      <div className="content-lines">{props.children}</div>
    </ContentArea>
  );
};

const renderLoading = () => {
  return (
    <Wrapper>
      <div className="progress-circle">
        <progress className="pure-material-progress-circular" />
      </div>
    </Wrapper>
  );
};

const renderLoadingFailed = () => {
  return (
    <Wrapper>
      We could not retrieve data &#128532; <br />
      Please try again in a minute...
    </Wrapper>
  );
};

const renderDetail = (currencyStats, currencyPlotData) => {
  const {
    name,
    image,
    symbol,
    market_data: marketData,
    categories,
    description,
    hashing_algorithm: hashingAlgorithm,
    genesis_date: genesisDate,
    developer_data: developerData,
  } = currencyStats;

  const btcPrice = marketData.current_price.btc;
  const ath = marketData.ath.btc;
  const btcAthDate = new Date(marketData.ath_date.btc);
  const btcPriceDrop = marketData.ath_change_percentage.btc;
  const btcMarketCap = marketData.market_cap.btc;
  const maxSupply = marketData.max_supply;

  const consensusAlgorithm = categories.filter((category) =>
    category.toLowerCase().startsWith("proof of"),
  )[0];

  const {
    closed_issues: closedIssues,
    commit_count_4_weeks: commitCount4Weeks,
    pull_request_contributors: pullRequestContributors,
    subscribers,
    code_additions_deletions_4_weeks: codeAdditionsDeletions4Weeks,
  } = developerData;

  console.log("currencyStats");
  console.log(currencyStats);
  console.log("genesisDate");
  console.log(genesisDate);

  // console.log("currencyPlotData")
  // console.log(currencyPlotData)

  const now = new Date();

  /////////

  const { prices } = currencyPlotData;

  let data = [
    {
      type: "scatter", // all "scatter" attributes: https://plot.ly/javascript/reference/#scatter
      x: prices.map((price) => new Date(price[0])),
      y: prices.map((price) => Math.round(price[1] * 100000000)),
      marker: {
        // marker is an object, valid marker keys: #scatter-marker
        color: "rgb(247,147,26)", // more about "marker.color": #scatter-marker-color
      },
    },
  ];
  let layout = {
    // all "layout" attributes: #layout
    title: symbol.toUpperCase() + " / BTC", // more about "layout.title": #layout-title
    xaxis: {
      // all "layout.xaxis" attributes: #layout-xaxis
      title: "Time", // more about "layout.xaxis.title": #layout-xaxis-title
    },
    yaxis: {
      // all "layout.xaxis" attributes: #layout-xaxis
      title: "SAT", // more about "layout.xaxis.title": #layout-xaxis-title
    },
  };
  let config = {
    showLink: false,
    displayModeBar: true,
  };

  /////////

  return (
    <Wrapper>
      <h3>
        <img src={image.thumb} className="image" />{" "}
        {`${name} [${symbol.toUpperCase()}]`}{" "}
      </h3>
      <p>
        Price: <b>{btcPrice ? Formatter.getSatPrice(btcPrice) : ""}</b>. Down{" "}
        <b>{btcPriceDrop ? Math.abs(btcPriceDrop).toFixed(1) : "-"} %</b> from
        its BTC ATH <b>{btcPrice ? Formatter.getSatPrice(ath) : ""}</b>. Bitcoin
        ATH was reached{" "}
        <b>
          {btcAthDate
            ? Formatter.getTime(now.getTime() - btcAthDate.getTime())
            : ""}
        </b>{" "}
        ago. Market Capitalization is{" "}
        <b>{btcMarketCap ? Formatter.getBtcPrice(btcMarketCap) : ""}</b>.
        Maximum supply is <b>{maxSupply ? "known" : "unknown"}</b>. Consensus
        algorithm is{" "}
        <b>{consensusAlgorithm ? consensusAlgorithm : "unknown"}</b>. Genesis
        date is <b>{genesisDate ?? "unknown"}</b>.
        {/* TODO: add percentage of supply */}
      </p>

      <p>
        <PlotlyComponent
          data={data}
          layout={layout}
          config={config}
        ></PlotlyComponent>
      </p>

      {/* <h4>Description</h4>
      <p>{description.en}</p> */}

      {/* <h4>Developer data</h4>
      <p>
        Closed issues in total: <b>{closedIssues}</b>. Commit count in last 4
        weeks: <b>{commitCount4Weeks}</b>. Pull request contributors:{" "}
        <b>{pullRequestContributors}</b>. Subscribers: <b>{subscribers}</b>.
      </p> */}
    </Wrapper>
  );
};

const loadCurrencyInfo = async (
  currencyId,
  setCurrencyStats,
  setCurrencyPltoData,
) => {
  const promises = [
    CoinGekkoAdapter.getCurrencyStats(currencyId),
    CoinGekkoAdapter.getCurrencyPlotData(currencyId),
  ];
  const [stats, plotData] = await Promise.allSettled(promises);

  if (stats.status === "fulfilled") setCurrencyStats(stats.value);
  if (plotData.status === "fulfilled") setCurrencyPltoData(plotData.value);
};
