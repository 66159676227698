import React, { Component, Fragment, useMemo } from "react";
import CoinGekkoAdapter from "../../utils/Adapters/CoinGekkoAdapter";
import { isNotValue } from "../../utils/general";
import ContentArea from "../ContentArea/ContentArea";
import { CurrencyCard } from "../CurrencyCard/CurrencyCard";
import "./Style.css";

const INITIAL_CURRENCY_COUNT = 30;
const CURRENCY_INTERVAL_COUNT = 30;
const CURRENCY_MAX_COUNT = 90;

type CurrencyInfo = {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: string;
  market_cap: string;
  ath_date: string;
  ath: string;
  ath_change_percentage: string;
};

type CurrencyStats = {
  id: string;
  currentBtcPrice: number;
  currentBtcMarketCap: number;
  btcAth: number;
  btcAthDate?: Date;
  btcAthDrop?: number;
};

type Props = {};
type State = {
  isLoading: boolean;
  currenciesStats: CurrencyStats[];
};

function delay(ms: number) {
  // TODO: add to utils
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class CurrencyCardArea extends Component<Props, State> {
  private topCurrencesInfo?: CurrencyInfo[];

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      currenciesStats: [],
    };
  }

  componentDidMount() {
    console.log("-------componentDidMount------------");
    this.initialize(); // TODO: add repeat if not loaded
  }

  private async initialize() {
    this.setState({ isLoading: true });

    this.topCurrencesInfo = (await CoinGekkoAdapter.getTopCurrencesByMarketCap(
      CURRENCY_MAX_COUNT + 1,
    )) as CurrencyInfo[];
    if (!this.topCurrencesInfo) {
      console.error("Unable to retrieve top currences info.");
      this.openDialog();
      this.setState({ isLoading: false });
      return;
    }

    this.setCurrenciesStats(INITIAL_CURRENCY_COUNT);
    this.setState({ isLoading: false });
  }

  private loadMoreCurrencyStats = async () => {
    const currentStatsCount = this.state.currenciesStats.length;
    if (currentStatsCount >= CURRENCY_MAX_COUNT) {
      console.warn("Unable to load more currency stats, limit exceeded");
      return;
    }

    if (!this.topCurrencesInfo) {
      console.error("CurrencesInfo was not retrieved yet");
      return;
    }

    const nextStatsCount = currentStatsCount + CURRENCY_INTERVAL_COUNT;
    this.setCurrenciesStats(nextStatsCount);
  };

  private setCurrenciesStats(count: number) {
    const currenciesStats = [];
    this.topCurrencesInfo.slice(1, count + 1).forEach((currencyInfo) => {
      const {
        id,
        current_price,
        market_cap,
        ath_date,
        ath,
        ath_change_percentage,
      } = currencyInfo;

      currenciesStats.push({
        id: id,
        currentBtcPrice: parseFloat(current_price),
        currentBtcMarketCap: parseFloat(market_cap),
        btcAthDate: new Date(ath_date),
        btcAth: parseFloat(ath),
        btcAthDrop: parseFloat(ath_change_percentage),
      });
    });

    this.setState({ currenciesStats });
  }

  private openDialog() {
    const modal = document.querySelector("#modalDialog") as any; // TODO: remove any

    if (modal) {
      modal.showModal();
    } else {
      console.error("Fail to load modal dialog");
    }
  }

  private renderCurrencyCards() {
    console.warn("renderCurrencyCards");
    if (!this.topCurrencesInfo) {
      console.error("Unable to render currency cards");
      return <Fragment></Fragment>;
    }

    const numberOfLoadedCurrences = this.state.currenciesStats.length;
    const currencesInfoToRender = this.topCurrencesInfo.slice(
      1,
      numberOfLoadedCurrences + 1,
    );

    return (
      <div className="content-cards">
        {currencesInfoToRender.map((currencyInfo, index) => {
          return this.renderCurrencyCard(currencyInfo, index + 1);
        })}
      </div>
    );
  }

  private renderCurrencyCard(currencyInfo: CurrencyInfo, position: number) {
    const { id, image, name, symbol } = currencyInfo;
    const currencyStats = this.state.currenciesStats.filter(
      (stats) => id === stats.id,
    )[0];
    let btcAthDrop, currentBtcMarketCap, btcAthDate, currentBtcPrice;
    if (currencyStats) {
      btcAthDrop = currencyStats.btcAthDrop;
      btcAthDate = currencyStats.btcAthDate;
      currentBtcMarketCap = currencyStats.currentBtcMarketCap;
      currentBtcPrice = currencyStats.currentBtcPrice;
    }

    return (
      <CurrencyCard
        key={id}
        id={id}
        image={image}
        name={name}
        symbol={symbol}
        btcPrice={currentBtcPrice}
        btcMarketCap={currentBtcMarketCap}
        btcPriceDrop={btcAthDrop}
        btcAthDate={btcAthDate}
        badge={position.toString()}
      />
    );
  }

  private renderLoading() {
    return (
      <div className="progress-circle">
        <progress className="pure-material-progress-circular" />
      </div>
    );
  }

  private renderLoadingFailed() {
    return (
      <div className="content-lines">
        We could not retrieve data &#128532; <br />
        Please try again in a minute...
      </div>
    );
  }

  private renderContent() {
    const { currenciesStats, isLoading } = this.state;

    if (isLoading) {
      if (currenciesStats?.length < INITIAL_CURRENCY_COUNT) {
        return this.renderLoading();
      }

      return (
        <Fragment>
          {this.renderCurrencyCards()}
          {/* TODO: render loading or skeletons */}
        </Fragment>
      );
    }

    if (currenciesStats?.length < INITIAL_CURRENCY_COUNT) {
      return this.renderLoadingFailed();
    }

    return this.renderCurrencyCards();
  }

  render() {
    const { currenciesStats, isLoading } = this.state;
    const currenciesStatsCount = currenciesStats.length;

    console.log("RENDER: Currences stats count: " + currenciesStatsCount);
    console.log("RENDER: isLoading: " + isLoading);

    const showBottomActions = currenciesStatsCount < CURRENCY_MAX_COUNT;
    const label =
      this.state.currenciesStats.length < INITIAL_CURRENCY_COUNT
        ? "Reload"
        : "Show more";

    const bottomAction = showBottomActions
      ? {
          // TODO: showBottomActions
          label: label, // TODO: should be reload as well if fails
          disabled: isLoading,
          handleClick: async () => {
            await this.loadMoreCurrencyStats();
          },
        }
      : undefined;

    return (
      // TODO: prevent re-rendering of contect https://alexsidorenko.com/blog/react-list-rerender/
      <ContentArea
        label="Largest cryptocurrencies in Bitcoin terms"
        bottomAction={bottomAction}
      >
        {this.renderContent()}

        {/* TODO: add modal dialog https://blog.logrocket.com/creating-reusable-pop-up-modal-react/ */}
      </ContentArea>
    );
  }
}

export default CurrencyCardArea;
