import React, { Component, Fragment } from "react"; // let's also import Component
import StatusDataLoader from "../../services/StatusDataLoader";
import { StatusData } from "../../shared/types";
import CurrencyCardArea from "../CurrencyCardArea/CurrencyCardArea";
import CurrentBlockStatsArea from "../CurrentBlockStatsArea/CurrentBlockStatsArea";
import FearAndGreedStatsArea from "../FearAndGreedStats/FearAndGreedStatsArea";
import MiningStatsArea from "../MiningStatsArea/MiningStatsArea";
import StatsArea from "../StatsArea/StatsArea";
import Layout from "./Layout/Layout";
import "./Style.css";

type Props = {};
type State = { status: StatusData };

export class HomePage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { status: undefined };
  }

  async componentDidMount() {
    this.setState({ status: await StatusDataLoader.getStatusData() });
  }

  render() {
    const { status } = this.state;
    return (
      <Layout>
        <StatsArea status={status} />
        <br />
        <CurrentBlockStatsArea status={status} />
        <br />
        <MiningStatsArea status={status} />
        <br />
        <FearAndGreedStatsArea status={status} />
        <br />
        <CurrencyCardArea />
        <br />
      </Layout>
    );
  }
}
